@import "../styles/common.scss";
@import "../styles/font.scss";


* {
  line-height: 1;
  margin: 0;
}

.hero-section {
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: responsiveSize(1198);
  color: black;


  &__illustration {
    position: absolute;
    background: url("../assets/images/masjid-illustration-desktop.svg");
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    top: 0;
    left: 0;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: white;
    border-radius: responsiveSize(56);
    width: responsiveSize(729);
    height: responsiveSize(819);
    gap: responsiveSize(20);
    margin-top: responsiveSize(-70);

    &--rain-animation {
      margin-top: responsiveSize(-91.5);
    }

    &--logo {
      width: responsiveSize(188.67);
      height: responsiveSize(81.65);
    }

    &--slogan {
      font-family: "Fredoka SemiCondensed";
      font-weight: 500;
      font-size: responsiveSize(45);
      line-height: responsiveSize(45);
      width: responsiveSize(662);
    }

    &--desc {
      font-family: "Baloo Bhaijaan 2";
      font-weight: 400;
      font-size: responsiveSize(28);
      line-height: responsiveSize(30);
      width: responsiveSize(547);
    }

  }

  &__info {
    position: relative;
    width: responsiveSize(552);
    flex-direction: column;
    gap: responsiveSize(20);

    
    &--mobile {
      display: none;
    }

    &--desktop {
      display: flex;
    }
  }

  &__heart-animation {
    position: absolute;
    width: responsiveSize(89);
    height: responsiveSize(89);
    right: responsiveSize(-65);
    top: responsiveSize(-16);
  }

  &__read-animation {
    position: absolute;
    width: responsiveSize(86);
    height: responsiveSize(86);
    right: responsiveSize(-70);
    top: responsiveSize(-14);
  }

  @media only screen and (max-width: 775px) {

    height: responsiveSizeMobile(863);
    flex-direction: column;
    gap: responsiveSizeMobile(23);

    &__illustration {
      background: url("../assets/images/masjid-illustration-mobile.svg");
      background-repeat: no-repeat;
      background-size: contain;
      left: 0;
      width: responsiveSizeMobile(775);
      height: responsiveSizeMobile(1103.57);
    }

    &__content {
      width: responsiveSizeMobile(461);
      height: responsiveSizeMobile(400);
      margin-top: responsiveSizeMobile(77);
      background-color: rgb(42, 52, 83, 0.8);

      &--rain-animation {
        margin-top: responsiveSizeMobile(-91.5);
      }

      &--logo {
        display: none;
      }

      &--slogan {
        font-family: "Fredoka SemiCondensed";
        font-weight: 500;
        font-size: responsiveSizeMobile(45);
        line-height: responsiveSizeMobile(42);
        width: responsiveSizeMobile(413);
        color: white;
      }

      &--desc {
        display: none;
      }
    }

    &__info {
      width: responsiveSizeMobile(678);
      gap: responsiveSizeMobile(30);

      &--cta {
        padding: responsiveSizeMobile(8) 0 !important;
      }

      &--desktop {
        display: none;
      }

      &--mobile {
        display: flex;
        align-items: center;
      }
    }

    &__heart-animation {
      top: responsiveSizeMobile(-14);
      right: responsiveSizeMobile(21);
      width: responsiveSizeMobile(89);
      height: responsiveSizeMobile(89);
    }

    &__read-animation {
      top: responsiveSizeMobile(-10);
      right: responsiveSizeMobile(21);
      width: responsiveSizeMobile(86);
      height: responsiveSizeMobile(86);
    }

    .stores-container {
      display: none;
    }

  }
}
