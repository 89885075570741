@import "../styles/common.scss";
@import "../styles/font.scss";


.ReactModalPortal {
  position: absolute;
  z-index: 100;
}

.ReactModal__Content {
  .close-btn {
    top: 40px;
    right: 40px;
    cursor: pointer;
    position: absolute;
  }
  .world-map {
    width: auto;
    height: 916px;
  }
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
  .ReactModal__Content {
    padding: 0 !important;
    transform-origin: 0 0;
    border: none !important;
    outline: none !important;
    overflow: auto !important;
    border-radius: 0 !important;
    background: transparent !important;
    transform: scale(var(--scale)) translate(-50%, -50%) !important;
  }
}

.about-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: responsiveSize(440);
  gap: responsiveSize(30);

  &__title {
    font-family: 'Fredoka SemiCondensed';
    font-weight: 500;
    font-size: responsiveSize(45);
    line-height: responsiveSize(45);
  }

  &__description {
    font-family: "Baloo Bhaijaan 2";
    font-weight: 400;
    font-size: responsiveSize(28);
    line-height: responsiveSize(30);
    text-align: left;
  }

  &__card-0 {
    .about-card__img-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: responsiveSize(306);
      height: responsiveSize(276);
      background-color: white;
      border-radius: responsiveSize(31);

      img {
        width: responsiveSize(263);
        height: responsiveSize(263);
        margin-right: responsiveSize(14.13);
      }
    }
  }

  &__card-1 {
    .about-card__img-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: responsiveSize(276);

      img {
        width: responsiveSize(275.28);
        height: responsiveSize(245.52);
      }
    }
  }

  &__card-2 {
    .about-card__img-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: responsiveSize(276);
      img {
        width: responsiveSize(296.36);
        height: responsiveSize(298.45);
      }
    }
  }

  @media only screen and (max-width: 775px) {

    width: responsiveSizeMobile(601.39);
    gap: responsiveSizeMobile(30);

    &__title {
      font-size: responsiveSizeMobile(55);
      line-height: responsiveSizeMobile(55);
    }

    &__description {
      font-size: responsiveSizeMobile(34);
      line-height: responsiveSizeMobile(36);
    }


    &__card-0 {
      .about-card__img-container {
        width: responsiveSizeMobile(306);
        height: responsiveSizeMobile(276);
        border-radius: responsiveSizeMobile(31);
  
        img {
          width: responsiveSizeMobile(263);
          height: responsiveSizeMobile(263);
          margin-right: responsiveSizeMobile(14.13);
        }
      }
    }
  
    &__card-1 {
      .about-card__img-container {
        height: responsiveSizeMobile(276);
  
        img {
          width: responsiveSizeMobile(328.33);
          height: responsiveSizeMobile(292.84);
        }
      }
    }

    &__card-2 {
      display: none;
    }
  }

}

.about {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: responsiveSize(30);
  background-color: black;

  &__card-container {
    display: flex;
    align-items: flex-start;
    gap: responsiveSize(60);
    margin-top: responsiveSize(28.1);
  }

  &__cta {
    width: responsiveSize(513) !important;
    text-transform: unset !important;
    padding: 0 !important;
    cursor: pointer;
  }

  @media only screen and (max-width: 775px) {

    &__card-container {
      flex-direction: column;
      gap: responsiveSizeMobile(31);
      margin-top: responsiveSizeMobile(40);
    }

    &__cta {
      display: none;
    }
  }
}