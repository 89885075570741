@import "../styles/common.scss";
@import "../styles/font.scss";



.muslim-show {
  position: relative;
  height: responsiveSize(1333.14);
  color: black;

  &__illustration {
    z-index: 2;
    position: absolute;
    background: url("../assets/images/muslim-show-illustration-desktop.svg");
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    top: 0;
    left: 0;
  }

  &__moon-illustration {
    z-index: 1;
    position: absolute;
    background: url("../assets/images/moon-illustration.svg");
    width: responsiveSize(1342);
    height: responsiveSize(1153.94);
    background-repeat: no-repeat;
    background-size: contain;
    top: responsiveSize(149);
    left: responsiveSize(130);
  }

  &__title {
    z-index: 3;
    position: absolute;
    top: responsiveSize(473);
    left: responsiveSize(239);
    font-family: 'Fredoka SemiCondensed';
    font-size: responsiveSize(78);
    line-height: responsiveSize(78);
    text-transform: uppercase;

    span {
      display: none;
    }
  }

  &__card {
    z-index: 3;
    position: absolute;
    left: responsiveSize(210);
    top: responsiveSize(557);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: responsiveSize(472);
    gap: responsiveSize(31);

    &--desc {
      font-family: "Baloo Bhaijaan 2";
      font-weight: 400;
      font-size: responsiveSize(28);
      line-height: responsiveSize(30);
      text-align: center;
    }

    &--social {
      display: flex;
      align-items: center;
      gap: responsiveSize(37);
      height: responsiveSize(97);

      a {
        height: 100%;
        width: 100%;
        svg {
          height: 100%;
          width: 100%;
        }
      }
    }

    &--cta {
      width: responsiveSize(426);
    }
  }

  @media only screen and (max-width: 775px) {

    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: responsiveSizeMobile(1813);
    padding-bottom: responsiveSizeMobile(66);

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: responsiveSizeMobile(550);
      background-color: #458884;
    }


    &__illustration {
      background: url("../assets/images/muslim-show-illustration-mobile.svg");
      height: responsiveSizeMobile(1333.14);
      background-size: cover;
    }

    &__moon-illustration {
      width: responsiveSizeMobile(1183.69);
      height: responsiveSizeMobile(906.39);
      left: responsiveSizeMobile(-120);
      top: responsiveSizeMobile(350);
    }

    &__title {
      display: flex;
      gap: responsiveSizeMobile(15);
      font-size: responsiveSizeMobile(68);
      line-height: responsiveSizeMobile(68);
      top: responsiveSizeMobile(500);
      left: responsiveSizeMobile(194);
      text-transform: none;

      span {
        display: block;
      }
    }

    &__card {
      position: relative;
      left: inherit;
      top: inherit;
      background-color: white;
      justify-content: center;
      border-radius: responsiveSizeMobile(33);
      width: responsiveSizeMobile(687);
      height: responsiveSizeMobile(578);
      gap: responsiveSizeMobile(30);

      &--desc {
        font-size: responsiveSizeMobile(34);
        line-height: responsiveSizeMobile(36);
      }

      &--social {
        gap: responsiveSizeMobile(49);
        height: responsiveSizeMobile(128);
      }

      &--cta {
        width: responsiveSizeMobile(426);
      }

    }

  }

}