@import "../styles/common.scss";
@import "../styles/font.scss";

.copyright {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: responsiveSize(15) 0;
  border-top: responsiveSize(2) solid white;
  font-size: responsiveSize(27);
  border-color: white;
  background-color: #274D79;
  font-family: "Avenir";
  font-weight: 900;


  @media only screen and (max-width: 775px) {
    height: fit-content;
    border-top: responsiveSizeMobile(2) solid white;
    padding: responsiveSizeMobile(15) 0;
    font-size: responsiveSizeMobile(27);
  }
}
