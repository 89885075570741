@import "../../styles/common.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: responsiveSize(183);
  height: responsiveSize(183);
  background-color: white;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16); 
  border-radius: 100%;

  &__logo {
    width: responsiveSize(144);
    height: responsiveSize(144);
  }

  @media only screen and (max-width: 775px) {
    width: responsiveSizeMobile(183);
    height: responsiveSizeMobile(183);
    box-shadow: none; 
    border-radius: 100%;

    &__logo {
      width: responsiveSizeMobile(144);
      height: responsiveSizeMobile(144);
    }
  }
}