@import "../../styles/common.scss";
@import "../../styles/font.scss";

.stores-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: responsiveSize(19);

  @media only screen and (max-width: 775px) {
    gap: responsiveSizeMobile(19);
    &:after {
      content: "";
      position: absolute;
      top: responsiveSizeMobile(-100);
      left: responsiveSizeMobile(-46);
      z-index: -1;
      width: responsiveSizeMobile(775);
      height: responsiveSizeMobile(281);
      background: linear-gradient(
        to bottom,
        #c0c3de responsiveSize(181),
        #eac676
      );
    }
  }

  &__link {
    background-color: black;
    white-space: nowrap;
    height: responsiveSizeMobile(97);
    min-width: responsiveSizeMobile(290);
    border-radius: responsiveSizeMobile(12);
    display: flex;
    align-items: center;
    padding: responsiveSizeMobile(8) responsiveSizeMobile(15);
    color: white;
    text-align: left;
    text-decoration: none;
    font-size: responsiveSizeMobile(25);

    img {
      height: 70%;
      margin-right: responsiveSizeMobile(15);
    }

    &--google {
      border: responsiveSizeMobile(2) solid #a2a2a1;
      background-color: #100f0d;
      text-transform: uppercase;
    }

    strong {
      font-size: responsiveSizeMobile(40);
      text-transform: none;
    }

    @media only screen and (min-width: 775px) {
      height: responsiveSize(65);
      min-width: responsiveSize(203);
      max-width: responsiveSize(219);
      border-radius: responsiveSize(12);
      padding: responsiveSize(2) responsiveSize(8);
      font-size: responsiveSize(18);

      img {
        margin-right: responsiveSize(8);
      }

      &--google {
        border: responsiveSize(3) solid #a2a2a1;
      }

      strong {
        font-size: responsiveSize(30);
      }
    }
  }
}
