@import "../styles/common.scss";

header {
  top: 0;
  z-index: 50;
  width: responsiveSize(var(--desktopWidthRef));
  margin: 0 auto;
  position: sticky;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  .burger-wrapper {
    display: none;
  }

  nav {
    display: flex;
    width: responsiveSize(1320);
    height: responsiveSize(103);
    margin: auto;

    a {
      display: flex;
      align-items: center;

      svg {
        width: responsiveSize(180);
        height: responsiveSize(81);
      }
    }

    .links {
      display: flex;
      align-items: center;

      a {
        font-weight: 700;
        font-size: responsiveSize(30);
        color: black;
        font-family: "Avenir";
        text-decoration: none;
        margin-left: responsiveSize(55);
      }
      
      .active {
        color: #4CB3C4;
        text-decoration: underline;
      }
    }

    .switch-language {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      svg {
        width: responsiveSize(22);
        height: responsiveSize(35);
      }

      select {
        // A reset of styles, including removing the default dropdown arrow
        appearance: none;
        // Additional resets for further consistency
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        width: fit-content;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
        outline: none;
        font-family: "Avenir";
        font-size: responsiveSize(47);
        color: black;
        font-weight: 700;

        option {
          font-size: responsiveSize(27);
        }
      }
    }
  }


  .burger {
    height: 100%;
    width: 100%;
    position: relative;
    font-size: 0.5em;
    cursor: pointer;
    overflow: hidden;
    -webkit-transition: 0.2s all;
    -o-transition: 0.2s all;
    transition: 0.2s all;
    -webkit-tap-highlight-color: transparent;
  }

  .burger .burger-lines:after {
    left: 0;
    top: -1em;
  }

  .burger .burger-lines:before {
    left: 0em;
    top: 1em;
  }

  .burger:after {
    content: "";
    display: block;
    position: absolute;
    height: 150%;
    width: 150%;
    top: -25%;
    left: -25%;
  }

  .burger .burger-lines {
    top: 50%;
    margin-top: -0.125em;
  }

  .burger .burger-lines,
  .burger .burger-lines:after,
  .burger .burger-lines:before {
    pointer-events: none;
    display: block;
    content: "";
    width: 3em;
    border-radius: 0.25em;
    background-color: #4cb3c4;
    height: 0.25em;
    position: absolute;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }

  .burger .burger-lines:after {
    left: 0;
    top: -1em;
  }

  .burger.burger-rotate .burger-lines:after,
  .burger.burger-rotate .burger-lines:before {
    width: 3em;
  }

  .burger.burger-rotate .burger-lines,
  .burger.burger-rotate .burger-lines:after,
  .burger.burger-rotate .burger-lines:before {
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  .burger.burger-rotate.open {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .burger.burger-rotate.open .burger-lines {
    background-color: transparent;
  }

  .burger.burger-rotate.open .burger-lines:before,
  .burger.burger-rotate.open .burger-lines:after {
    left: 0;
    top: 0;
  }

  .burger.burger-rotate.open .burger-lines:before {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  .burger.burger-rotate.open .burger-lines:after {
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }

  @media only screen and (max-width: 775px) {
    width: 100%;
    box-shadow: none;
    border-bottom: 1px solid #51aabc;

    nav {
      width: 100%;
      flex-direction: row-reverse;
      height: responsiveSizeMobile(103);

      .burger-wrapper {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 2;
        background-color: white;
        width: responsiveSizeMobile(165);

        .burger.burger-rotate {
          display: flex;
          align-items: center;
          width: responsiveSizeMobile(72);

          .burger-lines,
          .burger-lines:after,
          .burger-lines:before {
            width: responsiveSizeMobile(72);
            height: responsiveSizeMobile(12);
            border-radius: responsiveSizeMobile(12);
          }

          .burger-lines {
            top: responsiveSizeMobile(45);
          }

          .burger-lines:before {
            top: responsiveSizeMobile(21);
          }

          .burger-lines:after {
            top: responsiveSizeMobile(-21);
          }

          &.open .burger-lines:before,
          &.open .burger-lines:after {
            top: 0;
          }
        }
      }

      .switch-language {
        flex: unset;
        justify-content: center;
        z-index: 2;
        background-color: white;

        svg {
          width: responsiveSizeMobile(17);
          height: responsiveSizeMobile(28);
        }

        select {
          font-size: responsiveSizeMobile(44);

          option {
            font-size: responsiveSizeMobile(35);
          }
        }

        width: responsiveSizeMobile(165);
      }

      .logo {
        flex: 1;
        z-index: 2;
        background-color: white;
        justify-content: center;


        svg {
          width: responsiveSizeMobile(180);
          height: responsiveSizeMobile(81);
        }
      }

      .links {
        top: -200%;

        &.open {
          top: 100%;
        }

        transition: 0.3s top ease-in-out;
        z-index: 1;
        width: 100%;
        position: absolute;
        background-color: white;
        justify-content: center;
        flex-direction: column;

        a {
          color: black;
          font-weight: 900;
          text-transform: uppercase;
          justify-content: center;
          text-decoration: none !important;
          width: responsiveSizeMobile(699);
          font-size: responsiveSizeMobile(39);
          line-height: responsiveSizeMobile(50);
          margin: 0;
          padding-top: responsiveSizeMobile(11);
          padding-bottom: responsiveSizeMobile(11);
          border-top: 1px solid #51aabc;
        }
      }
    }
  }
}
