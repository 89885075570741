@import "../styles/common.scss";
@import "../styles/font.scss";


* {
  font-weight: inherit;
  margin: 0;
}

.video-presentation {
  z-index: 2;
  background: #eac676;
  position: relative;

  &__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 10;
    width: responsiveSize(1320);
    margin: 0 auto;
    padding-top: responsiveSize(47);
  }

  &__title {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: responsiveSize(45);
    line-height: responsiveSize(45);
    font-family: "Fredoka SemiCondensed";
    font-weight: 500;
    color: black;
    margin-bottom: responsiveSize(21.9);

    h2:first-child::after {
      content: " -\00a0";
      white-space: pre;
    }
  }

  &__vimeo {
    margin-top: inherit;
    width: responsiveSize(1355);
    height: responsiveSize(762);

    iframe {
      border: 0;
      border-radius: responsiveSize(59);
    }
  }

  &__cta {
    width: responsiveSize(908);
    margin-top: responsiveSize(41);
  }

  &__awlad-boat {
    flex: 1;
    display: flex;
    position: relative;
    align-items: flex-end;
    overflow: hidden;
    height: responsiveSize(400);
    margin-top: responsiveSize(100);

    &--boat {
      z-index: 2;
      position: absolute;
      bottom: responsiveSize(-88);
      left: responsiveSize(180);
      width: responsiveSize(751);
    }


    &--waves {
      z-index: 3;
      position: relative;
      margin-bottom: responsiveSize(-33) !important;
      width: 100%;
    }
  }

  @media only screen and (max-width: 775px) {

    background-color: white;
    margin-top: responsiveSizeMobile(-30);

    &__title {
      flex-direction: column;
      font-size: responsiveSizeMobile(27);
      line-height: responsiveSizeMobile(27);
      margin-bottom: responsiveSizeMobile(13.4);

      h2:first-child {
        text-transform: uppercase;
        font-size: responsiveSizeMobile(55);
        line-height: responsiveSizeMobile(55);
      }

      h2:first-child::after {
        content: "";
      }
    }

    &__vimeo {
      width: responsiveSizeMobile(728);
      height: responsiveSizeMobile(410);

      iframe {
        border-radius: responsiveSizeMobile(24);
      }
    }

    &__cta {
      width: responsiveSizeMobile(676.76);
      margin-top: responsiveSizeMobile(26);
      padding: responsiveSizeMobile(6) 0;
    }

    &__awlad-boat {
      width: 100%;
      height: responsiveSizeMobile(250);
      margin-top: responsiveSizeMobile(150);

      &--boat {
        bottom: responsiveSizeMobile(-70);
        left: responsiveSizeMobile(-90);
        width: responsiveSizeMobile(609);
        height: responsiveSizeMobile(406);
      }

      &--waves {
        position: absolute;
        margin-bottom: responsiveSizeMobile(443);
        width: responsiveSizeMobile(1270);
        height: responsiveSizeMobile(221);
      }
    }
  }
}
