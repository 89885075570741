@import "../styles/common.scss";
@import "../styles/font.scss";


* {
  font-weight: inherit;
  margin: 0;
}

.foulane {
  position: relative;
  height: responsiveSize(1194.25);
  padding-top: responsiveSize(150.9);


  &__illustration {
    position: absolute;
    background: url("../assets/images/foulane-illustration-desktop.svg");
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: responsiveSize(-150.9);
    left: 0;
  }

  &__title {
    font-family: "Fredoka SemiCondensed";
    font-weight: 500;
    font-size: responsiveSize(104);
    line-height: responsiveSize(104);
    margin-top: responsiveSize(-35);
    margin-left: responsiveSize(365);
  }

  &__card {
    z-index: 10;
    position: absolute;
    bottom: responsiveSize(80.3);
    right: responsiveSize(263);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: responsiveSize(56);
    width: responsiveSize(644);
    height: responsiveSize(909);
    gap: responsiveSize(23);
    color: black;

    &--title {
      font-family: "Fredoka SemiCondensed";
      font-weight: 500;
      font-size: responsiveSize(63);
      line-height: responsiveSize(63);
    }

    &--illustration {
      width: responsiveSize(583);
      border-radius: responsiveSize(18);
    }

    &--desc {
      font-family: "Baloo Bhaijaan 2";
      font-weight: 500;
      font-size: responsiveSize(28);
      line-height: responsiveSize(30);
      width: responsiveSize(542);
    }

    &--cta {
      width: responsiveSize(426);
    }
  }

  
  @media only screen and (max-width: 775px) {

    height: responsiveSizeMobile(1345);
    padding-top: 0;
    background-color: #458884;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: responsiveSizeMobile(-0);

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: responsiveSizeMobile(525);
      background-color: #6F7B4C;
    }



    &__illustration {
      background: url("../assets/images/foulane-illustration-mobile.svg");
      height: responsiveSizeMobile(779.45);
      background-size: contain;
      background-repeat: no-repeat;
      top: responsiveSizeMobile(41.3);
    }

    &__title {
      font-size: responsiveSizeMobile(68);
      line-height: responsiveSizeMobile(68);
      margin-top: 0;
      margin-left: responsiveSizeMobile(0);
      text-align: center;
    }

    &__card {
      position: relative;
      width: responsiveSizeMobile(687);
      height: responsiveSizeMobile(527);
      border-radius: responsiveSizeMobile(33);
      gap: responsiveSizeMobile(30);
      margin-bottom: responsiveSizeMobile(50);
      bottom: inherit;
      right: inherit;

      &--title, &--illustration {
        display: none;
      }

      &--desc {
        font-size: responsiveSizeMobile(34);
        line-height: responsiveSizeMobile(36);
        width: responsiveSizeMobile(598.2);
      }

    }

  }
}