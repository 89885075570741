@import "../styles/common.scss";
@import "../styles/font.scss";


* {
  font-weight: inherit;
  margin: 0;
}

.hiring {
  position: relative;
  height: responsiveSize(1449);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: responsiveSize(15);
  background-image: linear-gradient(180deg, #51AABC 0%, #274D79 100%);
  color: white;


  &__title {
    font-size: responsiveSize(111);
    line-height: responsiveSize(111);
    font-family: "Fredoka SemiCondensed";
    font-weight: 500;
    text-transform: uppercase;
  }

  &__desc {
    font-size: responsiveSize(48);
    line-height: responsiveSize(52);
    font-family: "Baloo Bhaijaan 2";
    font-weight: 400;
    text-align: center;
  }

  &__cta {
    text-decoration: none;
    color: #2D4F7C;
    background-color: white;
    font-family: 'Fredoka SemiCondensed';
    font-weight: 600;
    font-size: responsiveSize(50);
    line-height: responsiveSize(68);
    width: responsiveSize(610);
    text-align: center;
    border-radius: responsiveSize(14);
  }

  &__details {
    font-family: "Baloo Bhaijaan 2";
    font-weight: 400;
    font-size: responsiveSize(38);
    line-height: responsiveSize(41);
  }

  &__sub-animation {
    position: absolute;
    width: responsiveSize(758.07);
    height: responsiveSize(505.56);
    right: responsiveSize(120.8);
    top: responsiveSize(650);
    opacity: 0.5;
    rotate: -2deg;
  }

  &__logo-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: responsiveSize(24.5);
    bottom: responsiveSize(82.5);

    * {
      fill: white;
    }

    &--logo {
      width: responsiveSize(188.67);
      height: responsiveSize(81.65);
    }
  }


  @media only screen and (max-width: 775px) {

    background-image: linear-gradient(180deg, #51AABC 0%, #274D79 100%);
    height: responsiveSizeMobile(1414);
    gap: responsiveSizeMobile(25);
    padding-top: responsiveSizeMobile(30);

    &__title {
      font-size: responsiveSizeMobile(91);
      line-height: responsiveSizeMobile(91);
    }

    &__desc {
      font-size: responsiveSizeMobile(37);
      line-height: responsiveSizeMobile(40);
    }

    &__cta {
      z-index: 2;
      font-size: responsiveSizeMobile(50);
      line-height: responsiveSizeMobile(68);
      width: responsiveSizeMobile(610);
      border-radius: responsiveSizeMobile(14);
    }

    &__details {
      font-size: responsiveSizeMobile(37);
      line-height: responsiveSizeMobile(40);
    }

    &__sub-animation {
      z-index: 1;
      width: responsiveSizeMobile(719.5);
      height: responsiveSizeMobile(479.91);
      right: responsiveSizeMobile(-55);
      top: responsiveSizeMobile(530);
    }

    &__logo-container {  
      gap: responsiveSizeMobile(24.5);
      bottom: responsiveSizeMobile(194);

      &--logo {
        width: responsiveSizeMobile(188.67);
        height: responsiveSizeMobile(81.65);
      }
    }

  }
}