@import "../../styles/common.scss";
@import "../../styles/font.scss";

$themes: (
  'primary': (color: white, background-color: #4cb3c4),
  'secondary': (color: #2D4F7C, background-color: white),
  'tertiary': (color: white, background-color: #F24646)
);

@mixin theme($name, $properties) {
  &.#{$name} {
    @each $property, $value in $properties {
      #{$property}: $value;
    }
  }

  @media only screen and (min-width: 776px) {
    &.desktop-#{$name} {
      @each $property, $value in $properties {
        #{$property}: $value;
      }
    }
  }

  @media only screen and (max-width: 775px) {
    &.mobile-#{$name} {
      @each $property, $value in $properties {
        #{$property}: $value;
      }
    }
  }
}

.button {
  position: relative;
  text-decoration: none;
  font-family: 'Fredoka SemiCondensed';
  font-weight: 600;
  width: 100%;
  font-size: responsiveSize(40);
  line-height: responsiveSize(54);
  text-align: center;
  border-radius: responsiveSize(8);
  min-width: responsiveSize(426);
  text-transform: uppercase;
  white-space: nowrap;
  border: none;

  @each $name, $properties in $themes {
    @include theme($name, $properties);
  }

  @media only screen and (max-width: 775px) {
    font-size: responsiveSizeMobile(40);
    line-height: responsiveSizeMobile(54);
    border-radius: responsiveSizeMobile(8);
    min-width: responsiveSizeMobile(426);

    &.mobile-tertiary {
      font-size: responsiveSizeMobile(28);
      line-height: responsiveSizeMobile(37);
    }
  }
}