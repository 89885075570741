@import "../styles/common.scss";
@import "../styles/font.scss";

.buy-book {
  z-index: 1;
  display: flex;
  position: relative;
  height: responsiveSize(1211.36);

  &__illustration {
    position: absolute;
    background: url("../assets/images/walad-binti-illustration-desktop.svg");
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    top: 0;
    left: 0;
  }

  &__walad-binti-illustration {
    position: absolute;
    background: url("../assets/images/walad-binti-bench.svg");
    width: responsiveSize(577);
    height: responsiveSize(510);
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    left: 0;
  }

  &__container {
    z-index: 2;
    display: flex;
    margin: auto;
    gap: responsiveSize(81);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: responsiveSize(20);
    background-color: white;
    width: responsiveSize(644);
    height: responsiveSize(357);
    border-radius: responsiveSize(56);
    color: black;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-top: responsiveSize(160);

    &--title {
      font-family: "Fredoka SemiCondensed";
      font-weight: 500;
      font-size: responsiveSize(51);
      line-height: responsiveSize(51);
    }

    &--desc {
      font-family: "Baloo Bhaijaan 2";
      font-weight: 400;
      font-size: responsiveSize(28);
      line-height: responsiveSize(30);
    }

    &--cta {
      width: responsiveSize(426) !important;
    }
  }

  &__book-illustration {
    display: block;
    width: responsiveSize(711);
    height: responsiveSize(1004);

    &--mobile {
      display: none;
    }

  }

  @media only screen and (max-width: 775px) {

    height: responsiveSizeMobile(1202);

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: responsiveSizeMobile(300);
      background: linear-gradient(to top, white, rgba(255, 255, 255, 0.623));
    }

    &__illustration {
      background: url("../assets/images/walad-binti-illustration-mobile.svg");
      background-size: cover;
    }

    &__walad-binti-illustration {
      display: none;
    }

    &__container {
      flex-direction: column-reverse;
      align-items: center;
      gap: responsiveSizeMobile(43);
    }

    &__content {
      gap: responsiveSizeMobile(20);
      width: responsiveSizeMobile(687);
      height: responsiveSizeMobile(380);
      margin-top: 0;
      box-shadow: none;

      &--title {
        font-size: responsiveSizeMobile(51);
        line-height: responsiveSizeMobile(51);
      }

      &--desc {
        font-size: responsiveSizeMobile(28);
        line-height: responsiveSizeMobile(30);
      }

      &--cta {
        width: responsiveSizeMobile(426);
      }
    }


    &__book-illustration {
      display: block;
      width: responsiveSizeMobile(456);
      height: responsiveSizeMobile(644);

      &--desktop {
        display: none;
      }
    }
  }

}
